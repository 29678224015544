<template>
	<div class="animate__animated animate__fadeIn">
		<top-bar>
			<div class="top-box">
				<img :src="logoImg" class="logo">
				<div class="menus-box" :style="fontColor">
					<div class="menus" v-for="(m,mi) in menus" :style="active == m.path ? {fontWeight:600} : ''" :key="mi" @mouseover="changeOver(m)" :class="m.hover ? 'hover-bg' : '' " @mouseleave="mouseLeave(m)" @click="changePath(m)">
						{{m.name}}
						<div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
						<div class="se-me hover-bg" v-if="m.hover" >
							<div class="child" :style="fontColor" v-for="(c,ci) in m.child" :key="ci" @click.stop="changePath(c)">
								{{c.name}}
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</top-bar>
		<div class="project-box">
			<div class="bg">
				<div class="title">关于我们</div>
				<div class="tb">About us</div> 
			</div>
		</div>
		<div class="t-box">
			<div class="c-box">
				Introduction
				<div class="c-ti">公司介绍</div>
			</div>
		</div>
		<div class="r-box">
			<div class="fb">
				<div class="right-box">
					<!-- <div class="tt">全球首个工业规模co2 养护混凝土示范工程</div> -->
					<div class="cc">
					清洁捕获是一家利用人工技术清除二氧化碳的创新企业。致力于通过对碳捕捉、利用与封存（CCUS）技术的研发与商业化，为社会提供低成本的除碳技术解决方案，帮助构建未来“净零排放”的工业经济体系。
					</div>
					<div class="cc">
						目前企业聚焦于建材领域，利用技术将捕集的CO₂封存在混凝土材料中，不仅能制造减少80%碳足迹的低碳混凝土产品，为建筑、基建等行业提供不增加“绿色溢价”的低碳转型方案，同时还能实现CO₂的大规模、稳定封存，将混凝土这种全世界使用量第二大的材料转变为地球的碳汇。
					</div>
				</div>
				<img src="../static/home/sixbg.png" class="left-img">
			</div>
		</div>
		<div class="t-box">
			<div class="c-box">
				Philosophy
				<div class="c-ti">公司理念</div>
			</div>
		</div>
		<div>
			<div class="po-box">
				<div class="t-box">
					<div class="w-box">
						<div class="t1">企业愿景</div>
						<div class="t2">
							帮助全球减少1%的CO₂排放量
						</div>
					</div>
				</div>
			</div>
			<div class="pi-box">
				<div class="t-box">
					<div class="w-box">
						<div class="t1">企业使命</div>
						<div class="t2">
							通过人工技术恢复地球生态系统的碳平衡
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="t-box">
			<div class="c-box">
				Contact Us
				<div class="c-ti">联系我们</div>
			</div>
		</div>
		<div class="bu-box">
			<!-- <div class="y-box">
				<img src="../static/project/phone.png" >
				<div class="a1">电话</div>
				<div class="a2">15321001573</div>
			</div>-->
			<div class="y-box">
				<img src="../static/project/email.png" >
				<div class="a1">邮箱</div>
				<div class="a2">hi@cleanco2.cn</div>
			</div>
			<div class="y-box">
				<img src="../static/project/address.png" >
				<div class="a1">地址</div>
				<div class="a3">北京市朝阳区东三环北路38号好世界商业广场</div>
			</div>
		</div>
		<div style="width: 100%;margin-top: 259px;">
			<footer-bar path="About"></footer-bar>
		</div>
	</div>
</template>

<script>
	import topBar from '@/components/header-bar.vue'
	import utils from '@/utils/common.js'
	import footerBar from '@/components/footer-bar.vue'
	export default{
		components:{
			topBar,
			footerBar
		},
		data(){
			return{
				name:'About',
				menus:utils.menus,
				active:'About',
				swiperIndex:0,
				
			}
		},
		computed:{
			logoImg(){
				// console.log(this.swiperIndex,'lll')
				if(this.swiperIndex == 0){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 1){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 2){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 3){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 4){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 5){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 6){
					return require('../static/home/logo2.png')
				}
				
			},
			fontColor(){
				if(this.swiperIndex == 0){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						color:'#333'
					}
				}
			},
			dtBg(){
				if(this.swiperIndex == 0){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						background:'#333'
					}
				}
			}
		},
		methods:{
			changeOver(m){
				// console.log(m)
				if(m.path == 'solution'){
					m.hover = true
					this.active = m.path
				}
			},
			mouseLeave(m){
				this.menus.forEach(item=>item.hover = false)
				this.active = this.name
			},
			changePath(item){
				if(item.path != this.name && item.path != 'solution'){
					this.$router.push({
						name:item.path
					})
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.wrap {
	    width: 100%;
	    height: 100vh;
	    overflow: hidden;
	    // background: #eeeeee;
		margin: 0;
		padding: 0;
		
	}
		.active-h{
			font-weight: 600;
		}
	.top-box{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background: #007AFF;
		.logo{
			width: 42px;
			height: 47px;
			margin-left: 11.25rem;
		}
		.menus-box{
			height: 100%;
			display: flex;
			align-items: center;
			margin-right: 197px;
			position: relative;
		}
		.menus{
			height: 100%;
			width: 114px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 60px;
			font-size: 14px;
			font-weight: 400;
			
			cursor: pointer;
			box-sizing: border-box;
			position: relative;
			.dt-line{
				width: 20px;
				height: 4px;
				// background: #FFFFFF;
				position: absolute;
				bottom: 16px;
			}
			.se-me{
				position: absolute;
				width: 114px;
				height: 108px;
				bottom: -108px;
			}
			.child{
				width: 100%;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 400;
				// color: #FFFFFF;
			}
		}
	}
	
	.project-box{
		width: 100%;
		height: 400px;
		// background: rgba(0, 0, 0, 0.5);
		background-image: url(../static/project/about.png);
		position: relative;
		background-size: 100% 100%;

		.bg{
			padding-top: 182px;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.3);
			.title{
				font-size: 48px;
				color: #FFFFFF;
				margin-left: 175px;
			}
			.tb{
				font-size: 28px;
				font-weight: 400;
				color: #CCCCCC;
				margin-top: 12px;
				margin-left: 175px;
			}
		}

	}
	
	.t-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		margin-bottom: 80px;
		.c-box{
			width: 365px;
			height: 58px;
			font-size: 58px;
			font-weight: bold;
			color: #F7F7F7;
			position: relative;
			.c-ti{
				font-size: 32px;
				font-weight: 600;
				color: #1A1A1A;
				position: absolute;
				bottom: 0;
				left: 30%;
			}
		}
	}
	
	.r-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.fb{
			width: 1080px;
			height: 382px;
			background: #FFFFFF;
			box-shadow: 2px 2px 20px 7px rgba(0, 0, 0, 0.05);
			display: flex;
			
			justify-content: space-between;
			.left-img{
				width: 528px;
				height: 100%;
			}
			.right-box{
				width: 100%;
				height: 100%;
				padding: 40px 0 0 40px;
				flex: 1;
				box-sizing: border-box;
				.tt{
					font-size: 24px;
					font-weight: 500;
					color: #1A1A1A;
				}
				.cc{
					width: 472px;
					// height: 224px;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					margin-top: 25px;
				}
				.btn{
					width: 134px;
					height: 50px;
					background: #075AD3;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					font-weight: 500;
					border: 0;
					margin: 0;
					margin-left: auto;
					margin-right: 40px;
					color: #FFFFFF;
					img{
						width: 18px;
						height: 18px;
						margin-left: 6px;
					}
				}
			}
		}
	}
	.po-box{
		width: 100%;
		.t-box{
			width: 100%;
			height: 358px;
			background: url(../static/project/com.png);
			background-size: 100% 100%;
			.w-box{
				width: 621px;
				height: 358px;
				background: rgba(0, 0, 0, 0.5);
				margin-left: auto;
				// padding: 60px 0 0 60px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				.t1{
					font-size: 28px;
					font-weight: 600;
					color: #FFFFFF;
					text-align: center;
				}
				.t2{
					// width: 381px;
					// height: 140px;
					font-size: 16px;
					
					font-weight: 400;
					color: #FFFFFF;
					margin-top: 29px;
				}
			}
		}
	}
	.pi-box{
		width: 100%;
		margin-top: -100px;
		.t-box{
			width: 100%;
			height: 358px;
			background: url(../static/project/pt.png);
			background-size: 100% 100%;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			.w-box{
				width: 621px;
				height: 358px;
				background: rgba(0, 0, 0, 0.5);
				margin-right: auto;
				// padding: 60px 0 0 0;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				box-sizing: border-box;
				.t1{
					font-size: 28px;
					font-weight: 600;
					color: #FFFFFF;
				}
				.t2{
					// width: 381px;
					// height: 140px;
					font-size: 16px;
					font-weight: 400;
					color: #FFFFFF;
					margin-top: 29px;
				}
			}
		}
	}
	
	.bu-box{
		width: 100%;
		display: flex;
		align-items: flex-start; 
		justify-content: space-around;
		// background: #000;
		.y-box{
			width: 128px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 229px;
			img{
				width: 128px;
				height: 128px;
			}
			.a1{
				font-size: 20px;
				font-weight: 400;
				color: #4D4D4D;
				// margin-top: 24px;
			}
			.a2{
				font-size: 20px;
				font-weight: 500;
				color: #1A1A1A;
				margin-top: 8px;
			}
			.a3{
				margin-top: 8px;
				width: 312px;
				height: 56px;
				font-size: 20px;
				text-align: center;
				font-weight: 500;
				color: #1A1A1A;
			}
		}
	}
</style>
